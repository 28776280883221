import {
    brandDetail,
    getBrandGoods
} from '@/utils/goods.js';
export default {
    data() {
        return {
            brandInfo: '',
            evaluateNum: 0,
            evaluatePer: 0,
            sumMallGoods: 0,
            page: 1,
            oldPage: 0,
            goodsList: [],
            loading: false,
            finished: false,
            isDaPerson: ''
        }
    },
    created() {
        this.brandDetailFunc();
        this.isDaPerson = this.$cookies.get('isDaPerson');
    },
    methods: {
        // 获取品牌信息
        brandDetailFunc() {
            let data = {
                brandId: this.$route.query.brandId
            }
            brandDetail(data).then(res => {
                console.log('品牌', res)
                this.brandInfo = res.result.mallBrand;
                this.evaluateNum = res.result.evaluateNum;
                this.evaluatePer = res.result.evaluatePer;
                this.sumMallGoods = res.result.sumMallGoods;
                this.getBrandGoodsFunc();
            })
        },
        // 获取 品牌的其他商品
        getBrandGoodsFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                brandId: this.$route.query.brandId,
                pageNo: this.page,
                pageSize: 10
            }
            getBrandGoods(data).then(res => {
                console.log('商品', res)
                if (res.result) {
                    let arr = res.result.mallEntityGoodList;
                    arr.forEach(item => {
                        this.goodsList.push(item);
                    })
                    this.loading = false;

                    if (this.goodsList.length >= res.result.page.totalResult) {
                        this.finished = true;
                    } else {
                        this.page++;
                    }
                }
            })
        },
        // 处理商品图片
        farmPicFunc(img) {
            let url = img ? img.split(',')[0] : '';
            return url;
        },
        // 跳转商品详情
        gotoGoodsDetailFunc(id) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    goodsId: id
                }
            })
        }
    },
}