<template>
  <div class="brand">
    <div class="brand-bg"></div>
    <div class="brand-main">
      <div class="flex flex-between align-center brand-main-one">
        <div class="flex flex-left align-center brand-main-one-l">
          <img class="brand-main-one-l-logo" :src="brandInfo.icon" alt="">
          <div class="brand-main-one-l-info">
            <p class="brand-main-one-l-info-p1">{{brandInfo.name}}</p>
            <p class="brand-main-one-l-info-p2">来自中国</p>
          </div>
        </div>
        <div class="flex flex-left align-center brand-main-one-r">
          <img src="../../assets/img/brand/auth.png" alt="">
          认证品牌
        </div>
      </div>
      <div class="brand-main-two">
        <div class="brand-main-two-con">
          <div class="brand-main-two-con-title">品牌详情</div>
          <div class="brand-main-two-con-msg">
            {{brandInfo.details}}
          </div>
          <div v-if="brandInfo.img" class="brand-main-two-con-banner">
            <img :src="brandInfo.img" alt="">
          </div>
          <!-- <div class="flex flex-center align-center brand-main-two-con-more">
            点击查看更多详情<img src="../../assets/img/brand/down.png" alt="">
          </div> -->
        </div>
      </div>
      <div class="brand-main-three" style="display:none;">
        <div class="brand-main-three-box">
          <div class="brand-main-three-box-title">交易详情</div>
          <div class="flex flex-between brand-main-three-box-list">
            <div class="brand-main-three-box-list-div">
              <p class="brand-main-three-box-list-div-p1">{{sumMallGoods}}</p>
              <p class="brand-main-three-box-list-div-p2">上架商品数量</p>
            </div>
            <div class="brand-main-three-box-list-div">
              <p class="brand-main-three-box-list-div-p1">{{evaluatePer}}</p>
              <p class="brand-main-three-box-list-div-p2">评价数量</p>
            </div>
            <div class="brand-main-three-box-list-div">
              <p class="brand-main-three-box-list-div-p1">{{evaluateNum}}%</p>
              <p class="brand-main-three-box-list-div-p2">好评率</p>
            </div>
          </div>
        </div>
      </div>
      <div class="brand-main-four">
        <div class="flex flex-center align-center brand-main-four-title">
          <span></span>
          上架商品
          <span></span>
        </div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          @load="getBrandGoodsFunc"
        >
          <ul class="flex flex-left flex-wrap brand-main-four-ul">
            <li class="home-main-goods-list" v-for="item in goodsList" :key="item.id" @click="gotoGoodsDetailFunc(item.id)">
              <div class="flex flex-center align-center home-main-goods-list-img" :style="{background: 'url('+farmPicFunc(item.picture)+')no-repeat center center', backgroundSize: 'cover'}"></div>
              <div class="home-main-goods-list-name">
                {{item.name}}
              </div>
              <div class="home-main-goods-list-brand" v-if="item.brandName">
                品牌：{{item.brandName}}
              </div>
              <div class="flex flex-left home-main-goods-list-yh" v-if="item.couponName">
                <span class="home-main-goods-list-yh-span">{{item.couponName}}</span>
                <span class="home-main-goods-list-yh-span">得{{item.score}}龙珠</span>
              </div>
              <div class="flex flex-between align-center home-main-goods-list-price">
                <div class="home-main-goods-list-price-l">
                  ¥<span class="home-main-goods-list-price-l-num">{{parseFloat(item.salePrice/100).toFixed(2)}}</span>起
                  <span class="home-main-goods-list-price-l-toast">灵龙价</span>
                </div>
              </div>
              <div class="flex flex-between align-center home-main-goods-list-sale">
                <div class="home-main-goods-list-sale-l" v-if="(isDaPerson == '1' || isDaPerson == '2') && item.returnAmount > 0">
                  达人返 ¥{{parseFloat(item.returnAmount/100).toFixed(2)}}
                </div>
                <div class="home-main-goods-list-price-sale">销量{{item.saleNum}}</div>
                <!-- <div class="home-main-goods-list-price-sale" style="text-decoration: line-through;color:#999;">原价¥{{parseFloat(item.goodsOriginalPrice/100).toFixed(2)}}</div> -->
              </div>
            </li>
          </ul>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>